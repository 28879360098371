let NODE_ENV = "production";
// let NODE_ENV = 'demo';
// let NODE_ENV = 'local';

let Front_URL = "";
let baseUrl = "";
let BUSDAddress = "";
let BNBAddress = "";
let StakingContract = "";
let PriceProvider = "";
let BNBSYMBOL = "";
let toFixedSwap = "";
let toFixedBal = "";
let NetworkId = "";
let toFixed = "";
let networkName = "";
let toFixedvalue = "";
let txUrlAddress = "";
let txurl = "";
let tokenSymbol = "";
let AdminAddress = "";
let USDTAddress = "";
let DefiQToken = "";
let DefiqIDO = "";
let BnbRpcUrl = "";

if (NODE_ENV === "production") {
  Front_URL = "https://dopamineico.wealwindemo.com/";
  baseUrl = "https://dopamineicoapi.wealwindemo.com/";
  AdminAddress = "0x83c6bF7F45641b44a974FC68170A71A0e9C8109E"; //
  BUSDAddress = "0x2121606905753D5E7059db6776d98187A214539F"; //
  USDTAddress = "0xeC4c33BBE67a8B54Cc9b7a3603c47fA3d2C52367"; //
  DefiQToken = "0x6B3317E4a08F7BFc6D1C0b25c1907a315Ba161A4"; //dopamine token
  DefiqIDO = "0x56A2cd3B29546892764a98E26c340625aBc9e3cC"; /////
  txUrlAddress = "https://testnet.bscscan.com/address/";
  txurl = "https://testnet.bscscan.com/tx/";
  BnbRpcUrl = "https://data-seed-prebsc-2-s2.bnbchain.org:8545";
  BNBAddress = "";
  BNBSYMBOL = "BNB";
  toFixedSwap = 18;
  toFixedvalue = 8;
  NetworkId = 97;
  toFixed = 8;
  toFixedBal = 18;
  networkName = "Binance Chain";
  tokenSymbol = "DOPE";
} else if (NODE_ENV === "demo") {
  Front_URL = "http://localhost:3000/";
  baseUrl = "http://localhost:2053/";
  AdminAddress = "0x83c6bF7F45641b44a974FC68170A71A0e9C8109E"; //
  BUSDAddress = "0x2121606905753D5E7059db6776d98187A214539F"; //
  USDTAddress = "0xeC4c33BBE67a8B54Cc9b7a3603c47fA3d2C52367"; //
  DefiQToken = "0x6B3317E4a08F7BFc6D1C0b25c1907a315Ba161A4"; //dopamine token
  DefiqIDO = "0x56A2cd3B29546892764a98E26c340625aBc9e3cC"; /////
  // PriceProvider = "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526";
  txUrlAddress = "https://testnet.bscscan.com/address/";
  txurl = "https://testnet.bscscan.com/tx/";
  BnbRpcUrl = "https://data-seed-prebsc-2-s2.bnbchain.org:8545";
  BNBAddress = "";
  BNBSYMBOL = "BNB";
  toFixedSwap = 18;
  toFixedvalue = 8;
  NetworkId = 97;
  toFixed = 8;
  toFixedBal = 18;
  networkName = "Binance Chain";
  tokenSymbol = "DOPE";
} else {
  Front_URL = "http://localhost:3000/";
  baseUrl = "http://localhost:2053/";
  AdminAddress = "0x83c6bF7F45641b44a974FC68170A71A0e9C8109E"; //
  BUSDAddress = "0x2121606905753D5E7059db6776d98187A214539F"; //
  USDTAddress = "0xeC4c33BBE67a8B54Cc9b7a3603c47fA3d2C52367"; //
  DefiQToken = "0x6B3317E4a08F7BFc6D1C0b25c1907a315Ba161A4"; //dopamine token
  DefiqIDO = "0x56A2cd3B29546892764a98E26c340625aBc9e3cC"; /////
  // PriceProvider = "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526";
  txUrlAddress = "https://testnet.bscscan.com/address/";
  txurl = "https://testnet.bscscan.com/tx/";
  BnbRpcUrl = "https://data-seed-prebsc-2-s2.bnbchain.org:8545";
  BNBAddress = "";
  BNBSYMBOL = "BNB";
  toFixedSwap = 18;
  toFixedvalue = 8;
  NetworkId = 97;
  toFixed = 8;
  toFixedBal = 18;
  networkName = "Binance Chain";
  tokenSymbol = "DOPE";
}

let key = {
  Front_URL: Front_URL,
  USDTAddress: USDTAddress,
  baseUrl: baseUrl,
  BnbRpcUrl: BnbRpcUrl,
  AdminAddress: AdminAddress,
  BUSDAddress: BUSDAddress,
  BNBAddress: BNBAddress,
  DefiQToken: DefiQToken,
  DefiqIDO: DefiqIDO,
  StakingContract: StakingContract,
  PriceProvider: PriceProvider,
  BNBSYMBOL: BNBSYMBOL,
  toFixedSwap: toFixedSwap,
  toFixedBal: toFixedBal,
  toFixedvalue: toFixedvalue,
  NetworkId: NetworkId,
  toFixed: toFixed,
  networkName: networkName,
  txUrlAddress: txUrlAddress,
  txurl: txurl,
  tokenSymbol: tokenSymbol,
};

export default key;
