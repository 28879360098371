import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi'
import { bsc, bscTestnet } from 'viem/chains'
import { publicProvider } from 'wagmi/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { Provider } from "react-redux";
import store from './store'
//import components
import Home from './pages/home.js';
import Buytoken from "./pages/buytoken.js";
import { ToastContainer } from "react-toastify";
import Admin from "./pages/Admin";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc, bscTestnet],
  [publicProvider()],
)
// console.log({ chains }, 'chainschainschainswwwww')

// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
      shimDisconnect: true,
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: '681700e231a5aef269b7fe4adb34981a',
        version: '2',
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
})

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/buytoken", element: <Buytoken /> },
    { path: "/dopaminezrfgeryutdm", element: <Admin /> },

  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <Router basename="/">
        <WagmiConfig config={config}>
          <ToastContainer />
          <App />
        </WagmiConfig>
      </Router>
    </Provider>
  );
};

export default AppWrapper;