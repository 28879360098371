import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-scroll";

import logo from "../assets/images/logo.svg";
import $ from "jquery";
import Web3 from "web3";
import { setWallet } from "../reducers/Actions";
import {
  useWalletClient,
  useAccount,
  usePublicClient,
  useDisconnect,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import { useEthersSigner, walletClientToSigner } from "../helper/ethersconnect";
import WalletModal from "../pages/WalletModal";
import { connection } from "../helper/connection";
import { useDispatch, useSelector } from "react-redux";
import { toastAlert } from "../helper/toastAlert";
import config from "../config/config";
import {
  divideDecimal,
  formatAddress,
  toFixedFormat,
} from "../helper/customMath";
import { Multicall } from "ethereum-multicall";
import ABI from "../ABI/BEP20ABI.json";
import { gettokenBalance } from "../Action/contractActions";
var initialvalue = {
  BusdBalance: 0,
  UsdtBalance: 0,
  DefiQBalance: 0,
};
export default function Navbar(props) {
  let chainId = config.NetworkId;
  const dispatch = useDispatch();
  const [useraddress, setuseraddress] = useState("");
  const [balance, setbalance] = useState("");
  const location = useLocation();
  const [tokenBalance, setTokenBalance] = useState(initialvalue);
  const { chain } = useNetwork();
  const { disconnect, isSuccess, status } = useDisconnect();
  const { data: walletClient } = useWalletClient({ chainId });
  const walletConnection = useSelector((state) => state.walletConnection);
  // console.log('walletConnectioonononnnnn: ', walletConnection);
  const { BusdBalance, UsdtBalance, DefiQBalance } = tokenBalance;

  useAccount({
    onDisconnect: () => {
      localStorage.clear();
      console.log("Disconnected");
      dispatch(
        setWallet({
          network: walletConnection.network,
          web3: "",
          address: "",
          provider: "",
          connect: "no",
        })
      );
    },
  });
  useEffect(() => {
    if (isSuccess == true) {
      setTimeout(() => {
        localStorage.clear();
        window.location.reload(false);
      }, 500);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (chain && chain.id !== config.NetworkId) {
      dispatch(
        setWallet({
          network: "",
          web3: "",
          address: "",
          provider: "",
          connect: "",
          isChange: "true",
        })
      );
    } else {
      dispatch(
        setWallet({
          network: chainId,
          web3: walletConnection.web3,
          address: walletConnection.address,
          provider: walletConnection.provider,
          connect: "yes",
          isChange: "false",
        })
      );
      // window.location.reload(false)
    }
  }, [walletClient, chain?.network]);

  useEffect(() => {
    setConnection();
  }, [walletConnection.network, walletClient]);

  useEffect(() => {
    getuserBalance();
    gettokenBalancee();
  }, [walletConnection, walletClient]);

  async function gettokenBalancee() {
    let data = await gettokenBalance(tokenBalance, setTokenBalance);
  }

  async function setConnection() {
    if (chain && chain.id != config.NetworkId) {
      var errorMsg = "Please select " + config.networkName + " on your wallet";
      toastAlert("error", errorMsg, "network");
    }
    if (walletClient && chain && chain.id == config.NetworkId) {
      var { signer, transport } = walletClientToSigner(walletClient);
      var web3 = new Web3(transport);

      // console.log('userdetails: ', {
      //   network: config.NetworkId,
      //   web3: web3,
      //   address: walletClient.account.address,
      //   provider: transport,
      //   connect: "yes",
      //   isChange: "false",
      //   // userdetails: userdetails
      // });

      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: walletClient.account.address,
          provider: transport,
          connect: "yes",
          isChange: "false",
        })
      );
      getuserBalance();
      var get = await connection();
      // console.log('gesssssssssssssssssst: ', get);
      // console.log(signer, transport, '@@@@@@@@@@@@@@@@@@@1')
    } else {
      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: null,
          address: "",
          provider: null,
          connect: "yes",
          isChange: "false",
        })
      );
      // toastAlert('error', "Please select BSC chain network",'network');
    }
  }
  async function getuserBalance() {
    if (
      walletConnection &&
      walletConnection.web3 &&
      walletConnection.address &&
      walletConnection.address != ""
    ) {
      setuseraddress(walletConnection.address);
      var web3 = walletConnection.web3;
      var getBalance = await web3.eth.getBalance(walletConnection.address);
      var bal = getBalance / 10 ** 18;
      bal = await toFixedFormat(bal);
      setbalance(bal);
    }
  }

  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      10 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
    });
  }
  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-xl main_navbar navbar-sticky">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img src={logo} className="img-fluid brand_logo" alt="logo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                <ul className="links">
                  <li data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link
                      to="about"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                    >
                      About
                    </Link>
                  </li>
                  <li data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link
                      to="feature"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                    >
                      Features
                    </Link>
                  </li>
                  <li data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link
                      to="token"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                    >
                      Token
                    </Link>
                  </li>
                  <li data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link
                      to="platform"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                    >
                      Activity
                    </Link>
                  </li>
                  <li data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link
                      to="roadmap"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-0}
                    >
                      Roadmap
                    </Link>
                  </li>
                  <li data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link
                      to="faq"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                    >
                      FAQ
                    </Link>
                  </li>
                </ul>
                <div>
                  {walletConnection &&
                  walletConnection.address &&
                  walletConnection.address != "" &&
                  walletConnection.address != null &&
                  walletConnection.connect == "yes" ? (
                    <div className="dropdown me-3">
                      <a
                        className="primary_btn"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        // data-bs-target="#connect_wallet_modal"
                      >
                        {formatAddress(useraddress)}
                      </a>
                      <ul
                        className="dropdown-menu walletdrop"
                        aria-labelledby="dropdownMenuLink"
                      >
                        {location.pathname !== "/buytoken" && (
                          <li data-bs-dismiss="offcanvas" aria-label="Close">
                            <a href="/buytoken" className="dropdown-item">
                              Buy Token{" "}
                            </a>
                          </li>
                        )}
                        {location.pathname !== "/" && (
                          <li data-bs-dismiss="offcanvas" aria-label="Close">
                            <Link to={"/"} className="dropdown-item">
                              Home
                            </Link>
                          </li>
                        )}
                        <li>
                          <a className="dropdown-item" href="#">
                            <label className="me-2">BNB Balance:</label>
                            <span>
                              {balance && parseFloat(balance) > 0
                                ? parseFloat(balance).toFixed(8)
                                : 0}
                            </span>
                          </a>
                        </li>
                        {/* <li>
                          <a className="dropdown-item" href="#">
                            <label className="me-2">BUSD Balance:</label>
                            <span>
                              {BusdBalance && parseFloat(BusdBalance) > 0
                                ? parseFloat(BusdBalance)
                                : 0}
                            </span>
                          </a>
                        </li> */}
                        <li>
                          <a className="dropdown-item" href="#">
                            <label className="me-2">USDT Balance:</label>
                            <span>
                              {UsdtBalance && parseFloat(UsdtBalance) > 0
                                ? parseFloat(UsdtBalance)
                                : 0}
                            </span>
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <label className="me-2">Dopamine Balance:</label>
                            <span>
                              {DefiQBalance && parseFloat(DefiQBalance) > 0
                                ? parseFloat(DefiQBalance)
                                : 0}
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => disconnect()}
                          >
                            Disconnect
                          </a>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <>
                      <button
                        className="primary_btn"
                        data-bs-toggle="modal"
                        data-bs-target="#connect_wallet_modal"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                        >
                          <path
                            d="M14.3326 10.8011C13.9728 11.1504 13.7673 11.6531 13.8187 12.1898C13.8958 13.1099 14.7437 13.7829 15.6688 13.7829H17.2962V14.7968C17.2962 16.5603 15.8487 18.0001 14.0756 18.0001H4.24256C2.46952 18.0001 1.02197 16.5603 1.02197 14.7968V9.06318C1.02197 7.29966 2.46952 5.85986 4.24256 5.85986H14.0756C15.8487 5.85986 17.2962 7.29966 17.2962 9.06318V10.29H15.566C15.0863 10.29 14.6495 10.4774 14.3326 10.8011Z"
                            stroke="#2268E5"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            opacity="0.4"
                            d="M1.02197 9.82989V5.93655C1.02197 4.92274 1.64725 4.01964 2.59801 3.66182L9.39893 1.10599C10.461 0.705573 11.6002 1.48939 11.6002 2.62247V5.85985"
                            stroke="#15B383"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M18.2038 11.1587V12.9138C18.2038 13.3824 17.827 13.7657 17.3473 13.7827H15.6685C14.7434 13.7827 13.8955 13.1097 13.8184 12.1896C13.767 11.6529 13.9725 11.1502 14.3323 10.8009C14.6492 10.4772 15.086 10.2898 15.5657 10.2898H17.3473C17.827 10.3068 18.2038 10.6902 18.2038 11.1587Z"
                            stroke="#15B383"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            opacity="0.4"
                            d="M4.87598 9.48047H10.8718"
                            stroke="#15B383"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Connect Wallet
                        <span className="round"></span>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <WalletModal />

      {/* <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true" 
      >
        <div className="modal-dialog modal-dialog-centered" >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button">
                      <img
                        src={require("../assets/images/metamask.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>MetaMask</span>
                    </button>
                  </a>
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button">
                      <img
                        src={require("../assets/images/trustwallet.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>Trust Wallet</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
