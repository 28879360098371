import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";
import config from "../config/config";
import { getsocialmediaLink } from "../Action/ApiAction";

export default function Footer(props) {
  const [links, setlinks] = useState([]);

  useEffect(() => {
    getLink();
  }, []);

  const getLink = async () => {
    var { result } = await getsocialmediaLink();
    console.log(result, "resultkhvs");
    if (result && result.length > 0) {
      setlinks(result?.[0]);
    }
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="mb-3">
              <Link to="/">
                <img src={logo} className="img-fluid brand_logo" alt="logo" />
              </Link>
            </div>
            {/* <h6>Smart-contract address: </h6>
            <a className="footer_url" href={config.txUrlAddress + config.DefiqIDO} target="_blank">{config.DefiqIDO}</a>
            <h6>Token-contract address:</h6>
            <a className="footer_url" href={config.txUrlAddress + config.DefiQToken} target="_blank">{config.DefiQToken}</a>             */}
          </div>
          <div className="col-lg-6">
            <ul className="footer_social_links">
              <li>
                <a
                  href={links && links?.Facebook ? links?.Facebook : "#"}
                  target="_blank"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  href={links && links?.Linkedin ? links?.Linkedin : "#"}
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a
                  href={links && links?.Instagram ? links?.Instagram : "#"}
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href={links && links?.Youtube ? links?.Youtube : "#"}
                  target="_blank"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li>
                <a
                  href={links && links?.Twitter ? links?.Twitter : "#"}
                  target="_blank"
                >
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-12 copy_right">
            <p>
              Copyright © {new Date().getFullYear()} Dopamine ICO All Rights
              Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
