import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import config from "../config/config";
import { useSelector } from "react-redux";
import isEmpty from "is-empty";
import { toastAlert } from "../helper/toastAlert";
import {
  depositBNB,
  depositBUSD,
  depositUSDT,
  getAdminBalance,
} from "../helper/calculation";
import { approve, getbalance } from "../Action/contractActions";
import { saveBuyHistory } from "../Action/ApiAction";
import RecentTransactionnModal from "./RecentTransactionModal";
import { convert } from "../helper/convert";

const ConfirmationModal = forwardRef((props, ref) => {
  // console.log('proppropssssssssssssssssss: ', props);
  var fromvalue = props.fromvalue;
  var toValue = props.toValue;
  var tokenName = props.tokenName;
  var balance_ = props.balance_;
  var fromError = props.fromError;
  var settoValue = props.settoValue;
  var setValidError = props.setValidError;
  var setfromvalue = props.setfromvalue;
  const [transactionHashh, settransactionHash] = useState("");
  // const [rowsPerPage, setRowsPerPage] = useState(5)
  const [load, setLoad] = useState(false);
  const walletConnection = useSelector((state) => state.walletConnection);

  async function removemodelfunction() {
    setTimeout(() => window.$("#confirm_modal").modal("hide"), 600);
  }
  useImperativeHandle(ref, () => ({
    async confirm_click(from, status) {
      // console.log('statsdsdus: ', from);()
      window.$("#confirm_modal").modal("show");
    },
  }));

  const BuyTokens = async (e) => {
    // e.preventDefault()

    try {
      // console.log(fromvalue, toValue, 'eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
      if (
        fromvalue <= 0 ||
        isEmpty(fromvalue) ||
        toValue <= 0 ||
        isEmpty(toValue)
      ) {
        setLoad(false);
        window.$("#confirm_modal").modal("hide");
        let error = "Please enter valid amount";
        console.log("error: ", error);
        return setValidError(error);
      } else {
        const getadminBal = await getAdminBalance();
        // console.log('getadminBal: ', getadminBal);
        if (parseFloat(getadminBal) < parseFloat(toValue)) {
          setLoad(false);
          return toastAlert("error", "Insufficient Balance(Dopamine)");
        } else {
          let address =
            walletConnection && walletConnection.address !== ""
              ? walletConnection.address
              : "";
          if (isEmpty(address)) {
            return toastAlert("error", "Please connect your wallet");
          }
          let result = {};
          if (tokenName == "BUSD") {
            if (
              fromError.insuffucient == "yes" ||
              parseFloat(balance_.balance) < parseFloat(fromvalue)
            ) {
              return toastAlert("error", "Insufficient BUSD Balance");
            }
            if (fromError.allowance === "yes") {
              setLoad(true);
              var { status } = await approve(config.BUSDAddress, fromvalue);
              if (status == true) {
                setLoad(true);
                result = await depositBUSD(tokenName, fromvalue);
              } else {
                setLoad(false);
                return toastAlert("error", "Insufficient Approve Amount");
              }
            } else {
              setLoad(true);
              result = await depositBUSD(tokenName, fromvalue);
            }
          } else if (tokenName == "USDT") {
            if (
              fromError.insuffucient == "yes" ||
              parseFloat(balance_.balance) < parseFloat(fromvalue)
            ) {
              return toastAlert("error", "Insufficient USDT Balance");
            }
            if (fromError.allowance === "yes") {
              setLoad(true);
              var { status } = await approve(config.USDTAddress, fromvalue);
              if (status == true) {
                setLoad(true);
                result = await depositUSDT(tokenName, fromvalue);
              } else {
                setLoad(false);
                return toastAlert("error", "Insufficient Approve Amount");
              }
            } else {
              setLoad(true);
              result = await depositUSDT(tokenName, fromvalue);
            }
          } else if (tokenName == "BNB") {
            let bnbbalance = await getbalance(address, tokenName);
            let getbnbbal = bnbbalance && bnbbalance.balance;
            // console.log(getbnbbal,fromvalue,'fromvaluefromvaluefromvalue')
            if (parseFloat(getbnbbal) < parseFloat(fromvalue)) {
              setLoad(false);
              return toastAlert(
                "error",
                `Insufficient ${config.BNBSYMBOL} balance`
              );
            } else {
              setLoad(true);
              result = await depositBNB(tokenName, fromvalue, toValue);
            }
          }
          if (!isEmpty(result)) {
            settransactionHash(result.transactionHash);
            // getUserDetail()
            // getQRRBalancee()
            fromvalue = fromvalue.toString();
            fromvalue = await convert(fromvalue);
            toValue = toValue.toString();
            toValue = await convert(toValue);

            let data = {
              useraddress: address,
              currency: tokenName,
              depositAmt: fromvalue,
              earnedAmt: toValue,
              transactionHash: result.transactionHash,
            };
            let { status } = await saveBuyHistory(data);
            if (status == true) {
              // toastAlert('success', 'Success')
              window.$("#withdraw_success_modal").modal("show");
              setLoad(false);
              setfromvalue(0);
              settoValue(0);
              props.getbuyList(1, 5);
              // window.$("#confirm_modal").modal("hide")
            }
          } else {
            setLoad(false);
            settransactionHash("");
            toastAlert("error", "Transaction Rejected");
          }
        }
      }
    } catch (err) {
      console.log("errrssr: ", err);
    }
  };

  const cancelDatas = async () => {
    setTimeout(() => window.$("#confirm_modal").modal("hide"), 100);
  };
  return (
    <div>
      <div
        class="modal fade primary_modal"
        id="confirm_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="place_bid_modalCenteredLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5
                for="bid"
                style={{ fontWeight: "400", fontSize: "21px", margin: "auto" }}
              >
                {load == true
                  ? "Please don't refresh this page"
                  : "Please click confirm to proceed further"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={removemodelfunction}
              ></button>
            </div>
            <div class="modal-body px-0">
              <form className="px-4 bid_form">
                <div className="row d-flex justify-content-center">
                  <div className="col-12 col-sm-6">
                    {load == false ? (
                      <div>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => cancelDatas()}
                        >
                          Cancel
                        </button>{" "}
                        &nbsp;&nbsp;&nbsp;
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => BuyTokens()}
                        >
                          Confirm
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-success"
                        disabled
                      >
                        Processing........
                      </button>
                    )}
                    <RecentTransactionnModal
                      transactionHash={transactionHashh}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ConfirmationModal;
