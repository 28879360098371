import React, { useEffect, useState } from "react";
import d1 from "../assets/images/d1.svg";
import d2 from "../assets/images/d2.svg";
import d3 from "../assets/images/d3.svg";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { getbuyhistory, getfaqlist } from "../Action/ApiAction";
import { shortText } from "../helper/customMath";
import { getTimeAgo } from "../helper/dateformat";
import config from "../config/config";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const [historyList, sethistoryList] = useState([]);
  const [faq, setfaq] = useState([]);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    getbuyList(1, 10);
    getfaq();
  }, []);

  const getfaq = async () => {
    var { result } = await getfaqlist();
    setfaq(result);
  };

  const getbuyList = async (skip, limit) => {
    let data = {
      skip: skip,
      limit: limit,
    };
    var { result } = await getbuyhistory(data);
    sethistoryList(result);
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    centerMode: true,
    centerPadding: "0px",
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  var settings1 = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  var slider_single = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    fade: false,
    adaptiveHeight: true,
    useTransform: true,
    speed: 400,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    asNavFor: nav2,
    ref: (slider1) => setNav1(slider1),
  };

  var slider_nav = {
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "50px",
    dots: false,
    swipeToSlide: true,
    focusOnSelect: true,
    asNavFor: nav1,
    ref: (slider2) => setNav2(slider2),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div className="overflow-hidden position-relative">
        {/* <ScrollToTopOnMount /> */}
        <Navbar />

        <div className="page_header">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <div className="box">
                  <h1>Participate in the Ongoing ICO Token Sale</h1>
                  <p>
                    We have over 15 year experience in business consulting
                    arena. We have over 15 year experience in business
                    consulting arena and artificial intelligence year experience
                    in business.
                  </p>
                  <div className="btn_grps">
                    <Link to="/buytoken" className="primary_btn">
                      Buy Token <span className="round"></span>
                    </Link>
                    <a htref="#" className="primary_btn primary_btn_grey">
                      WHITEPAPER <span className="round"></span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <div className="img_box">
                  <img
                    src={require("../assets/images/banner.png")}
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="about" name="about">
          <div className="container">
            <div className="shade">
              <div className="aboutbg">
                <div className="row">
                  <div
                    className="col-lg-5 m-auto"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../assets/images/about.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div
                    className="col-lg-7 m-auto"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <h2 className="h2tag mb-4">
                      Invest in our popular <span>ICO</span> and become part of
                      the future of digital finance.
                    </h2>
                    <p>
                      In an ICO, a project or startup issues a new digital
                      token, typically based on a blockchain platform such as
                      Ethereum. These tokens serve a specific purpose within the
                      project's ecosystem, such as accessing services
                    </p>
                    <ul>
                      <li>Dcentralized Platform</li>
                      <li>Crowd Wisdom</li>
                      <li>Token Sale Phases </li>
                      <li>Rewards Mechanism</li>
                      <li>Investor Protection</li>
                      <li>Exchange Listing</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="feature" name="feature">
          <div className="container">
            <div
              className="head pb-2"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2 className="h2tag">
                Our <span>Dopamine</span> ICO Features
              </h2>
              <p className="para_tag">
                A blockchain solution platform is a comprehensive software or
                infrastructure that enables businesses and developers to build,
              </p>
            </div>
            <div className="row feature_box_row">
              <div
                className="col-xl-4 col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <div className="feature_box_wrapper">
                  <div className="feature_box_top"></div>
                  <div className="feature_box">
                    <div className="feature_box_img">
                      <img
                        src={require("../assets/images/feature_icon_01.png")}
                        className="img-fluid"
                      />
                    </div>
                    <h2>Safe & Secure</h2>
                    <div className="feature_box_hidden">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry Lorem galley of type.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400"
              >
                <div className="feature_box_wrapper">
                  <div className="feature_box_top"></div>
                  <div className="feature_box">
                    <div className="feature_box_img">
                      <img
                        src={require("../assets/images/feature_icon_02.png")}
                        className="img-fluid"
                      />
                    </div>
                    <h2>Early Bonus</h2>
                    <div className="feature_box_hidden">
                      <p>
                        Securing user data is of utmost importance to protect
                        user privacy and maintain trust.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1100"
                data-aos-delay="700"
              >
                <div className="feature_box_wrapper">
                  <div className="feature_box_top"></div>
                  <div className="feature_box">
                    <div className="feature_box_img">
                      <img
                        src={require("../assets/images/feature_icon_03.png")}
                        className="img-fluid"
                      />
                    </div>
                    <h2>Universal Access</h2>
                    <div className="feature_box_hidden">
                      <p>
                        Securing user data is of utmost importance to protect
                        user privacy and maintain trust.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1100"
                data-aos-delay="700"
              >
                <div className="feature_box_wrapper">
                  <div className="feature_box_top"></div>
                  <div className="feature_box">
                    <div className="feature_box_img">
                      <img
                        src={require("../assets/images/feature_icon_04.png")}
                        className="img-fluid"
                      />
                    </div>
                    <h2>Secure Storage</h2>
                    <div className="feature_box_hidden">
                      <p>
                        Securing user data is of utmost importance to protect
                        user privacy and maintain trust.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1100"
                data-aos-delay="700"
              >
                <div className="feature_box_wrapper">
                  <div className="feature_box_top"></div>
                  <div className="feature_box">
                    <div className="feature_box_img">
                      <img
                        src={require("../assets/images/feature_icon_05.png")}
                        className="img-fluid"
                      />
                    </div>
                    <h2>Low Cost</h2>
                    <div className="feature_box_hidden">
                      <p>
                        Securing user data is of utmost importance to protect
                        user privacy and maintain trust.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1100"
                data-aos-delay="700"
              >
                <div className="feature_box_wrapper">
                  <div className="feature_box_top"></div>
                  <div className="feature_box">
                    <div className="feature_box_img">
                      <img
                        src={require("../assets/images/feature_icon_06.png")}
                        className="img-fluid"
                      />
                    </div>
                    <h2>Several Profit</h2>
                    <div className="feature_box_hidden">
                      <p>
                        Securing user data is of utmost importance to protect
                        user privacy and maintain trust.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="token" name="token">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-5"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <div className="token_info_box">
                  <img
                    src={require("../assets/images/img_01.png")}
                    className="img-fluid d-block mb-4"
                  />
                  <h2 className="h2tag pb-3">Our Token Info</h2>
                  <h5>
                    Token distribution refers to the process of allocating and
                    distributing tokens within a blockchain ecosystem.
                  </h5>
                  <h5>
                    When a new blockchain project is launched, a specific number
                    of tokens are created, and these tokens are distributed
                    among various stakeholders, including founders.
                  </h5>
                </div>
              </div>
              <div
                className="col-lg-7"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img
                  src={require("../assets/images/chart.png")}
                  className="img-fluid d-block mb-4"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="platform" name="platform">
          <div className="container">
            <h2 className="h2tag" data-aos="fade-up" data-aos-duration="1000">
              Platform Recent Activity
            </h2>
            <Slider
              {...settings}
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              {historyList &&
                historyList.length > 0 &&
                historyList.map((item) => {
                  return (
                    <div
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="300"
                    >
                      <div className="platform_box_wrapper">
                        <div className="platform_box_top"></div>
                        <div className="box">
                          <img
                            src={require("../assets/images/activity_logo.png")}
                            className="img-fluid"
                          />
                          <div className="flx">
                            <div className="inbox">
                              <h5 className="h5tag">Purchased Token</h5>
                              <p>{item.earnedAmt}</p>
                            </div>
                            <div className="inbox">
                              <h5 className="h5tag">User ID</h5>
                              <p>{shortText(item.address)}</p>
                            </div>
                          </div>
                          <a
                            href={config.txurl + item.transactionHash}
                            target="_blank"
                          >
                            <img
                              src={require("../assets/images/arw.png")}
                              className="img-fluid me-2"
                            />
                            {getTimeAgo(item.createdAt)}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {/* <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <div className="platform_box_wrapper">
                  <div className="platform_box_top"></div>
                  <div className="box">
                    <img
                      src={require("../assets/images/activity_logo.png")}
                      className="img-fluid"
                    />
                    <div className="flx">
                      <div className="inbox">
                        <h5 className="h5tag">Purchased Token</h5>
                        <p>50</p>
                      </div>
                      <div className="inbox">
                        <h5 className="h5tag">User ID</h5>
                        <p>Dopamine 001</p>
                      </div>
                    </div>
                    <a>
                      <img
                        src={require("../assets/images/arw.png")}
                        className="img-fluid me-2"
                      />
                      5 hours{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <div className="platform_box_wrapper">
                  <div className="platform_box_top"></div>
                  <div className="box">
                    <img
                      src={require("../assets/images/activity_logo.png")}
                      className="img-fluid"
                    />
                    <div className="flx">
                      <div className="inbox">
                        <h5 className="h5tag">Purchased Token</h5>
                        <p>50</p>
                      </div>
                      <div className="inbox">
                        <h5 className="h5tag">User ID</h5>
                        <p>Dopamine 001</p>
                      </div>
                    </div>
                    <a>
                      <img
                        src={require("../assets/images/arw.png")}
                        className="img-fluid me-2"
                      />
                      5 hours{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <div className="platform_box_wrapper">
                  <div className="platform_box_top"></div>
                  <div className="box">
                    <img
                      src={require("../assets/images/activity_logo.png")}
                      className="img-fluid"
                    />
                    <div className="flx">
                      <div className="inbox">
                        <h5 className="h5tag">Purchased Token</h5>
                        <p>50</p>
                      </div>
                      <div className="inbox">
                        <h5 className="h5tag">User ID</h5>
                        <p>Dopamine 001</p>
                      </div>
                    </div>
                    <a>
                      <img
                        src={require("../assets/images/arw.png")}
                        className="img-fluid me-2"
                      />
                      5 hours{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <div className="platform_box_wrapper">
                  <div className="platform_box_top"></div>
                  <div className="box">
                    <img
                      src={require("../assets/images/activity_logo.png")}
                      className="img-fluid"
                    />
                    <div className="flx">
                      <div className="inbox">
                        <h5 className="h5tag">Purchased Token</h5>
                        <p>50</p>
                      </div>
                      <div className="inbox">
                        <h5 className="h5tag">User ID</h5>
                        <p>Dopamine 001</p>
                      </div>
                    </div>
                    <a>
                      <img
                        src={require("../assets/images/arw.png")}
                        className="img-fluid me-2"
                      />
                      5 hours{" "}
                    </a>
                  </div>
                </div>
              </div> */}
            </Slider>
          </div>
        </section>

        <section className="token_details" name="platform">
          <div className="container">
            <div className="row text-center">
              <div
                className="col-md-12"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <h2 className="h2tag mb-4">ICO Tokens Details and Sale</h2>
                <p className="para_tag">
                  Stay Informed with Live Data and Analysis
                </p>
              </div>
            </div>
            <div class="row">
              <div
                class="col-xl-4"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <div class="token-info__img">
                  <img
                    loading="lazy"
                    src={require("../assets/images/img_02.png")}
                    class="img-fluid"
                    alt=""
                  />{" "}
                </div>
              </div>
              <div
                class="col-xl-8"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <div class="token-info--info-wrap ul_li">
                  <ul class="token-info__list token-info--start">
                    <li class="elementor-repeater-item-f88422d">
                      <h4>Start</h4>
                      <span>Nov 27, 2023 (06:04PM GMT)</span>
                    </li>
                    <li class="elementor-repeater-item-72be74c">
                      <h4>Number of Tokens for Sale</h4>
                      <span>1.000.000 Tokens</span>
                    </li>
                    <li class="elementor-repeater-item-0c90526">
                      <h4>Minimal Transaction</h4>
                      <span>10 Tokens / Transaction</span>
                    </li>
                  </ul>
                  <ul class="token-info__list token-info--end">
                    <li class="elementor-repeater-item-118ad28">
                      <h4>End</h4>
                      <span>Dec 31, 2023 (06:04PM GMT)</span>
                    </li>
                    <li class="elementor-repeater-item-b6916f9">
                      <h4>Tokens exchange rate</h4>
                      <span>1 ETH = 650 ICC, 1 BTC = 1940 ICC</span>
                    </li>
                    <li class="elementor-repeater-item-a1639e3">
                      <h4>Acceptable currencies</h4>
                      <span>ETH, BTC, LTC</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="roadmap_new" name="roadmap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Slider {...slider_nav} className="slider_nav">
                  <div>
                    <div className="slider_top_nav">
                      <h2>Q1 2023</h2>
                      <h3>Air Drop</h3>
                    </div>
                  </div>
                  <div>
                    <div className="slider_top_nav">
                      <h2>Q2 2023</h2>
                      <h3>Defi Token Site Launch</h3>
                    </div>
                  </div>
                  <div>
                    <div className="slider_top_nav">
                      <h2>Q3 2023</h2>
                      <h3>ICO Sale</h3>
                    </div>
                  </div>
                  <div>
                    <div className="slider_top_nav">
                      <h2>Q4 2023</h2>
                      <h3>Listing on DEX</h3>
                    </div>
                  </div>
                  <div>
                    <div className="slider_top_nav">
                      <h2>Q5 2023</h2>
                      <h3>Listing on CEX</h3>
                    </div>
                  </div>
                  <div>
                    <div className="slider_top_nav">
                      <h2>Q6 2023</h2>
                      <h3>NFT's Launch</h3>
                    </div>
                  </div>
                </Slider>
                <Slider {...slider_single} className="slider_single">
                  <div>
                    <div className="slider_bottom">
                      <h2>Q1 2023</h2>
                      <div className="slider_bottom_content">
                        <h3>Air Drop</h3>
                        <p className="para_tag">
                          Integer quis turpis tempor, imperdiet lectus laoreet,
                          volutpat sem. Duis imperdiet leo magna, sit amet
                          venenatis sapien semper ac. Aenean dignissim mi
                          tristique laoreet bibendum. Ut metus purus, lacinia id
                          tellus eu, iaculis scelerisque est. Donec ex lectus,
                          imperdiet at tempor in, laoreet non dui. Phasellus vel
                          felis cursus, egestas urna vel, convallis lectus. Nunc
                          massa est, molestie ac semper at, fringilla a ex.
                          Mauris tincidunt purus vitae fermentum lobortis. Proin
                          feugiat efficitur lorem ut mollis.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider_bottom">
                      <h2>Q2 2023</h2>
                      <div className="slider_bottom_content">
                        <h3>Defi Token Site Launch</h3>
                        <p className="para_tag">
                          Integer quis turpis tempor, imperdiet lectus laoreet,
                          volutpat sem. Duis imperdiet leo magna, sit amet
                          venenatis sapien semper ac. Aenean dignissim mi
                          tristique laoreet bibendum. Ut metus purus, lacinia id
                          tellus eu, iaculis scelerisque est. Donec ex lectus,
                          imperdiet at tempor in, laoreet non dui. Phasellus vel
                          felis cursus, egestas urna vel, convallis lectus. Nunc
                          massa est, molestie ac semper at, fringilla a ex.
                          Mauris tincidunt purus vitae fermentum lobortis. Proin
                          feugiat efficitur lorem ut mollis.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider_bottom">
                      <h2>Q3 2023</h2>
                      <div className="slider_bottom_content">
                        <h3>ICO Sale</h3>
                        <p className="para_tag">
                          Integer quis turpis tempor, imperdiet lectus laoreet,
                          volutpat sem. Duis imperdiet leo magna, sit amet
                          venenatis sapien semper ac. Aenean dignissim mi
                          tristique laoreet bibendum. Ut metus purus, lacinia id
                          tellus eu, iaculis scelerisque est. Donec ex lectus,
                          imperdiet at tempor in, laoreet non dui. Phasellus vel
                          felis cursus, egestas urna vel, convallis lectus. Nunc
                          massa est, molestie ac semper at, fringilla a ex.
                          Mauris tincidunt purus vitae fermentum lobortis. Proin
                          feugiat efficitur lorem ut mollis.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider_bottom">
                      <h2>Q4 2023</h2>
                      <div className="slider_bottom_content">
                        <h3>Listing on DEX</h3>
                        <p className="para_tag">
                          Integer quis turpis tempor, imperdiet lectus laoreet,
                          volutpat sem. Duis imperdiet leo magna, sit amet
                          venenatis sapien semper ac. Aenean dignissim mi
                          tristique laoreet bibendum. Ut metus purus, lacinia id
                          tellus eu, iaculis scelerisque est. Donec ex lectus,
                          imperdiet at tempor in, laoreet non dui. Phasellus vel
                          felis cursus, egestas urna vel, convallis lectus. Nunc
                          massa est, molestie ac semper at, fringilla a ex.
                          Mauris tincidunt purus vitae fermentum lobortis. Proin
                          feugiat efficitur lorem ut mollis.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider_bottom">
                      <h2>Q5 2023</h2>
                      <div className="slider_bottom_content">
                        <h3>Listing on CEX</h3>
                        <p className="para_tag">
                          Integer quis turpis tempor, imperdiet lectus laoreet,
                          volutpat sem. Duis imperdiet leo magna, sit amet
                          venenatis sapien semper ac. Aenean dignissim mi
                          tristique laoreet bibendum. Ut metus purus, lacinia id
                          tellus eu, iaculis scelerisque est. Donec ex lectus,
                          imperdiet at tempor in, laoreet non dui. Phasellus vel
                          felis cursus, egestas urna vel, convallis lectus. Nunc
                          massa est, molestie ac semper at, fringilla a ex.
                          Mauris tincidunt purus vitae fermentum lobortis. Proin
                          feugiat efficitur lorem ut mollis.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="slider_bottom">
                      <h2>Q6 2023</h2>
                      <div className="slider_bottom_content">
                        <h3>NFT's Launch</h3>
                        <p className="para_tag">
                          Integer quis turpis tempor, imperdiet lectus laoreet,
                          volutpat sem. Duis imperdiet leo magna, sit amet
                          venenatis sapien semper ac. Aenean dignissim mi
                          tristique laoreet bibendum. Ut metus purus, lacinia id
                          tellus eu, iaculis scelerisque est. Donec ex lectus,
                          imperdiet at tempor in, laoreet non dui. Phasellus vel
                          felis cursus, egestas urna vel, convallis lectus. Nunc
                          massa est, molestie ac semper at, fringilla a ex.
                          Mauris tincidunt purus vitae fermentum lobortis. Proin
                          feugiat efficitur lorem ut mollis.
                        </p>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>

        <section className="faq" name="faq">
          <div className="container">
            <div className="head" data-aos="fade-up" data-aos-duration="1000">
              <h2 className="h2tag">Frequently Asked Questions</h2>
              <p className="mt-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.
              </p>
            </div>
            <div class="accordion" id="accordionExample">
              {faq &&
                faq.length > 0 &&
                faq.map((items, index) => (
                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <span>QA : {index + 1}</span>
                    <h2 class="accordion-header" id={`headingOne${index}`}>
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapseOne${index}`}
                        aria-expanded="true"
                        aria-controls={`collapseOne${index}`}
                      >
                        {items.Question}
                      </button>
                    </h2>
                    <div
                      id={`collapseOne${index}`}
                      class={`accordion-collapse collapse ${
                        index == 0 ? "show" : ""
                      }`}
                      aria-labelledby={`headingOne${index}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">{items.Answer}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
